import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "yuesao"
};
const _hoisted_3 = {
  class: "yuesao_twoall"
};
const _hoisted_4 = {
  class: "yuesao_two"
};
const _hoisted_5 = {
  class: "yuesao_three"
};
const _hoisted_6 = {
  class: "yuesao_four"
};
const _hoisted_7 = {
  class: "koubeiall"
};
const _hoisted_8 = {
  class: "koubei"
};
const _hoisted_9 = {
  class: "koubei_three acea-row row-between-wrapper"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  class: "koubei_pic"
};
const _hoisted_12 = {
  class: "koubei_title"
};
const _hoisted_13 = {
  class: "koubei_deso"
};
const _hoisted_14 = {
  class: "yuesao_five"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_zrcheader = _resolveComponent("zrcheader");
  const _component_el_image = _resolveComponent("el-image");
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  const _component_zrcfooter = _resolveComponent("zrcfooter");
  const _component_loading = _resolveComponent("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_zrcheader, {
    menunum: $data.menunum
  }, null, 8, ["menunum"]), _createVNode(_component_swiper, {
    "slides-per-view": 1,
    modules: $setup.modules,
    navigation: "",
    pagination: {
      clickable: true
    },
    style: {
      "height": "600px",
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_swiper_slide, null, {
      default: _withCtx(() => [_createVNode(_component_el_image, {
        src: "http://aibaobao.pflipin.com/zrcpic/yuesao_banner.jpg"
      })]),
      _: 1
    }), _createVNode(_component_swiper_slide, null, {
      default: _withCtx(() => [_createVNode(_component_el_image, {
        src: "http://aibaobao.pflipin.com/zrcpic/yuesao_banner.jpg"
      })]),
      _: 1
    }), _createVNode(_component_swiper_slide, null, {
      default: _withCtx(() => [_createVNode(_component_el_image, {
        src: "http://aibaobao.pflipin.com/zrcpic/yuesaobanner.jpg"
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modules"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_image, {
    class: "yuesao_image",
    src: "http://aibaobao.pflipin.com/zrcpic/yuesao1.png"
  })]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_image, {
    class: "yuesao_two_image",
    src: "http://aibaobao.pflipin.com/zrcpic/yuesao2.png"
  })])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_image, {
    class: "yuesao_three_image",
    src: "http://aibaobao.pflipin.com/zrcpic/yuesao3.png"
  })]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_image, {
    class: "yuesao_four_image",
    src: "http://aibaobao.pflipin.com/zrcpic/yuesao4.png"
  })]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_cache[0] || (_cache[0] = _createElementVNode("div", {
    class: "koubei_one"
  }, "20000+家庭的口碑见证", -1)), _cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "koubei_two"
  }, "放心稳定的住家保姆 说到做到的服务 爱宝宝一直在努力", -1)), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.koubeilist, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "koubei_item",
      onClick: $event => _ctx.goyuesao(item.id)
    }, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_el_image, {
      class: "koubei_image",
      src: item.pic
    }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_12, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_13, _toDisplayString(item.deso), 1)], 8, _hoisted_10);
  }), 256))])])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_el_image, {
    class: "yuesao_five_image",
    src: "http://aibaobao.pflipin.com/zrcpic/yuesao5.png"
  })]), _createVNode(_component_zrcfooter), _createVNode(_component_loading, {
    status: $data.loadingStatus
  }, null, 8, ["status"])]);
}