import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "yuesao"
};
const _hoisted_3 = {
  class: "yuesao_twoall"
};
const _hoisted_4 = {
  class: "yuesao_two"
};
const _hoisted_5 = {
  class: "yuesao_three"
};
const _hoisted_6 = {
  class: "yuesao_four"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_zrcheader = _resolveComponent("zrcheader");
  const _component_el_image = _resolveComponent("el-image");
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  const _component_zrcfooter = _resolveComponent("zrcfooter");
  const _component_loading = _resolveComponent("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_zrcheader, {
    menunum: $data.menunum
  }, null, 8, ["menunum"]), _createVNode(_component_swiper, {
    "slides-per-view": 1,
    modules: $setup.modules,
    navigation: "",
    pagination: {
      clickable: true
    },
    style: {
      "height": "600px",
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_swiper_slide, null, {
      default: _withCtx(() => [_createVNode(_component_el_image, {
        src: "http://aibaobao.pflipin.com/zrcpic/chanhoukf_banner.jpg"
      })]),
      _: 1
    }), _createVNode(_component_swiper_slide, null, {
      default: _withCtx(() => [_createVNode(_component_el_image, {
        src: "http://aibaobao.pflipin.com/zrcpic/chanhoukf_banner.jpg"
      })]),
      _: 1
    }), _createVNode(_component_swiper_slide, null, {
      default: _withCtx(() => [_createVNode(_component_el_image, {
        src: "http://aibaobao.pflipin.com/zrcpic/chanhoukf_banner.jpg"
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modules"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_image, {
    class: "yuesao_image",
    src: "http://aibaobao.pflipin.com/zrcpic/chanhoukf1.png"
  })]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_image, {
    class: "yuesao_two_image",
    src: "http://aibaobao.pflipin.com/zrcpic/chanhoukf2.png"
  })])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_image, {
    class: "yuesao_three_image",
    src: "http://aibaobao.pflipin.com/zrcpic/chanhoukf3.png"
  })]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_image, {
    class: "yuesao_four_image",
    src: "http://aibaobao.pflipin.com/zrcpic/chanhoukf4.png"
  })]), _createVNode(_component_zrcfooter), _createVNode(_component_loading, {
    status: $data.loadingStatus
  }, null, 8, ["status"])]);
}