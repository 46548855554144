import "core-js/modules/es.array.push.js";
import { $h, Tips, basePost, U, baseGet, URL, SplitArray, urlParams } from '@/utils/util.js';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import loading from '@/components/loading/loading.vue';
import zrcheader from '@/components/zrcheader/zrcheader.vue';
import zrcfooter from '@/components/zrcfooter/zrcfooter.vue';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
export default {
  name: 'IndexView',
  data() {
    return {
      menunum: 2,
      //
      aboutvedio_options: {
        width: '100%',
        //播放器高度
        height: '380px',
        //播放器高度
        color: "#409eff",
        //主题色
        currentTime: 2,
        speed: false,
        //关闭进度条拖动
        title: '',
        //视频名称
        src: "https://zhqcpic.oss-cn-chengdu.aliyuncs.com/zhqcpic/20240222/TENNIS1-2024-02-21-16-11-15-366.mp4",
        //视频源
        controlBtns: ['volume', 'fullScreen']
      },
      huodonglist: [{
        "id": 1,
        "title": "母婴护理",
        "pic": "http://aibaobao.pflipin.com/zrcpic/team1.jpg"
      }, {
        "id": 2,
        "title": "家庭育儿",
        "pic": "http://aibaobao.pflipin.com/zrcpic/team2.jpg"
      }, {
        "id": 3,
        "title": "产后康复",
        "pic": "http://aibaobao.pflipin.com/zrcpic/team3.jpg"
      }, {
        "id": 4,
        "title": "护理培训",
        "pic": "http://aibaobao.pflipin.com/zrcpic/team4.jpg"
      }, {
        "id": 5,
        "title": "母婴护理",
        "pic": "http://aibaobao.pflipin.com/zrcpic/team5.jpg"
      }, {
        "id": 6,
        "title": "家庭育儿",
        "pic": "http://aibaobao.pflipin.com/zrcpic/team6.jpg"
      }, {
        "id": 7,
        "title": "产后康复",
        "pic": "http://aibaobao.pflipin.com/zrcpic/team7.jpg"
      }, {
        "id": 8,
        "title": "护理培训",
        "pic": "http://aibaobao.pflipin.com/zrcpic/team8.jpg"
      }],
      //服务列表

      rongyulist: [{
        "id": 1,
        "title": "西南母婴服务行业市场品质品牌",
        "pic": "http://aibaobao.pflipin.com/zrcpic/rongyu1.jpg"
      }, {
        "id": 2,
        "title": "澳洲婴儿语言日本EduQ系列课程",
        "pic": "http://aibaobao.pflipin.com/zrcpic/rongyu2.jpg"
      }, {
        "id": 3,
        "title": "国际邓斯坦婴儿语言导师",
        "pic": "http://aibaobao.pflipin.com/zrcpic/rongyu3.jpg"
      }, {
        "id": 4,
        "title": "家政行业协会会员单位",
        "pic": "http://aibaobao.pflipin.com/zrcpic/rongyu4.jpg"
      }],
      //荣誉列表

      zrctoken: '',
      loadingStatus: true
    };
  },
  components: {
    loading,
    zrcheader,
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y]
    };
  },
  created() {
    var that = this;
    this.getParams();
    setTimeout(() => {
      this.loadingStatus = false;
    }, 2000);
  },
  onLoad(option) {
    console.log('参数');
  },
  watch: {},
  methods: {
    getParams() {
      var that = this;
      // 取到路由带过来的参数
      //console.log(that.$route.query);
      const option = this.$route.query;
      that.id = option.id || 1;
    },
    //提交保存任务
    lijidenglu: function () {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrpublic_api',
        a: 'dengluyanz_new',
        q: {
          curzdf: that.$data.curzdf,
          sjtel: that.$data.dltel,
          sfcard: that.$data.dlsfz,
          dlpass: that.$data.dlmima,
          yanzma: that.$data.yanzma
        }
      }, URL);
      baseGet(url, function (res) {
        Tips('登录成功', 'success');
        store.commit('SetZrctoken', res.data.zrctoken);
        router.push({
          path: '/home'
        });
      }, function (res) {
        Tips(res.msg, 'warning');
      });
    }
  },
  mounted: function () {
    var that = this;
  }
};