import { createRouter, createWebHashHistory } from 'vue-router'
import IndexView from '../views/IndexView'
import LoginView from '../views/LoginView'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import TeamView from '../views/TeamView.vue'
import ServerView from '../views/ServerView.vue'
import YuesaoView from '../views/YuesaoView.vue'
import YueersaoView from '../views/YueersaoView.vue'
import MuruView from '../views/MuruView.vue'
import ChanhoukfView from '../views/ChanhoukfView.vue'
import YuezicanView from '../views/YuezicanView.vue'
import KoubeilistView from '../views/KoubeilistView.vue'
import MuyinglistView from '../views/MuyinglistView.vue'
import ContantView from '../views/ContantView.vue'


const routes = [
  {
    path: '/',
    name: 'index',
    meta:{title:"首页"},
    component: IndexView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta:{title:"关于我们"},
  },
  {
    path: '/team',
    name: 'team',
    component: TeamView,
    meta:{title:"团队介绍"},
  },
  {
    path: '/server',
    name: 'server',
    component: ServerView,
    meta:{title:"服务优势"},
  },
  {
    path: '/yuesao',
    name: 'yuesao',
    component: YuesaoView,
    meta:{title:"特色服务-月嫂"},
  },
  {
    path: '/yueersao',
    name: 'yueersao',
    component: YueersaoView,
    meta:{title:"特色服务-月儿嫂"},
  },
  {
    path: '/muru',
    name: 'muru',
    component: MuruView,
    meta:{title:"特色服务-母乳指导"},
  },
  {
    path: '/chanhoukf',
    name: 'chanhoukf',
    component: ChanhoukfView,
    meta:{title:"特色服务-产后康复"},
  },
  {
    path: '/yuezican',
    name: 'yuezican',
    component: YuezicanView,
    meta:{title:"特色服务-月子餐"},
  },
  {
    path: '/koubeilist',
    name: 'koubeilist',
    component: KoubeilistView,
    meta:{title:"口碑见证-列表"},
  },
  {
    path: '/muyinglist',
    name: 'muyinglist',
    component: MuyinglistView,
    meta:{title:"母婴课堂-列表"},
  },
  {
    path: '/contant',
    name: 'contant',
    component: ContantView,
    meta:{title:"联系我们"},
  },
  
  {
    path: '/login',
    name: 'login',
    meta:{title:"学员登录"},
    component: LoginView
   
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta:{title:"学员中心"},
  }
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title; // 设置页面标题
  }
  next();
});
export default router
