import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';

const instance = axios.create({   // 创建实例

	//baseURL: "https://www.zcbz2023.com",
  //配置代理解决跨域用到
	//baseURL: "/api",
	timeout: 60000,
  // s三方接口不支持携带cookie，那么 屏蔽
  // withCredentials: true,
});
instance.interceptors.request.use(  //请求拦截
	(config)=>{
    const { 
      headers, 
      data, 
      params } = config;
    const contentType = headers['Content-Type'];
    if (data instanceof FormData) {
      return config;
    }
    const formatDataAndParams = data =>
    _.assignWith({}, data, (objVal, srcVal) => {
      if (_.isObject(srcVal)) {
        return JSON.stringify(srcVal);
      }
    });
    // axios请求数据问题： https://juejin.im/post/5a49ee676fb9a045132b0c69
    if ((_.isUndefined(contentType) || contentType === 'application/x-www-form-urlencoded') && (_.isObject(data) || _.isObject(params))) {
      let _config = { ...config };
      if (data) {
        _config.data = qs.stringify(formatDataAndParams(data));
      }

      if (params) {
        _config.params = formatDataAndParams(params);
      }

      return _config;
    }
		return config;
	},
	(err)=>{
		Promise.reject(err)
	}
)

instance.interceptors.response.use(   //响应拦截
	(res)=>{
    return res
	},
	(err)=>{
		Promise.reject(err)
	}
)

export default instance

