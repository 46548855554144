import { $h, Tips, basePost, U, baseGet, URL, SplitArray, urlParams } from '@/utils/util.js';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import loading from '@/components/loading/loading.vue';
import zrcheader from '@/components/zrcheader/zrcheader.vue';
import zrcfooter from '@/components/zrcfooter/zrcfooter.vue';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
export default {
  name: 'IndexView',
  data() {
    return {
      menunum: 3,
      koubeilist: [{
        "id": 1,
        "title": "四川成都仝泽娟阿姨",
        "pic": "http://aibaobao.pflipin.com/zrcpic/koubei1.jpg",
        "deso": '阿姨工作负责、仔细，婴儿抚触、早教互动、辅食搭配都很有经验。'
      }, {
        "id": 2,
        "title": "四川成都刘美阿姨",
        "pic": "http://aibaobao.pflipin.com/zrcpic/koubei2.jpg",
        "deso": '阿姨工作负责、仔细，婴儿抚触、早教互动、辅食搭配都很有经验。'
      }, {
        "id": 3,
        "title": "四川成都仝泽娟阿姨",
        "pic": "http://aibaobao.pflipin.com/zrcpic/koubei3.jpg",
        "deso": '阿姨工作负责、仔细，婴儿抚触、早教互动、辅食搭配都很有经验。'
      }, {
        "id": 4,
        "title": "四川成都刘美阿姨",
        "pic": "http://aibaobao.pflipin.com/zrcpic/koubei4.jpg",
        "deso": '阿姨工作负责、仔细，婴儿抚触、早教互动、辅食搭配都很有经验。'
      }],
      //口碑列表

      zrctoken: '',
      loadingStatus: true
    };
  },
  components: {
    loading,
    zrcheader,
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y]
    };
  },
  created() {
    var that = this;
    setTimeout(() => {
      this.loadingStatus = false;
    }, 2000);
  },
  onLoad(option) {
    console.log('参数');
  },
  watch: {},
  methods: {},
  mounted: function () {
    var that = this;
  }
};