import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "dibuall"
};
const _hoisted_2 = {
  class: "dibu"
};
const _hoisted_3 = {
  class: "dibu_one acea-row row-between-wrapper"
};
const _hoisted_4 = {
  class: "dibu_one_si"
};
const _hoisted_5 = {
  class: "dibu_gzh"
};
const _hoisted_6 = {
  class: "dibu_gzh_pic"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[2] || (_cache[2] = _createStaticVNode("<div class=\"dibu_one_yi\"><div class=\"dibu_rexian\">服务热线</div><div class=\"dibu_tel\">199-8201-2479（李女士）</div><div class=\"dibu_zongbu\">地址</div><div class=\"dibu_dizhi\">成都市龙泉驿区成龙大道二段1666号</div><div class=\"dibu_dizhi\">成都经开区产业孵化园B2-205附2号</div></div><div class=\"dibu_one_er\"><div class=\"dibu_title\">科研服务</div><div class=\"dibu_item\">材料测试</div><div class=\"dibu_item\">模拟计算</div></div><div class=\"dibu_one_san\"><div class=\"dibu_title\">关于我们</div><div class=\"dibu_item\">新手帮助</div><div class=\"dibu_item\">常见问题</div><div class=\"dibu_item\">最新动态</div><div class=\"dibu_item\">联系我们</div></div>", 3)), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_image, {
    src: "https://zcbiaozheng.oss-cn-chengdu.aliyuncs.com/zcbzcss/gongzhonghao.jpg"
  })]), _cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "dibu_gzh_txt"
  }, "众诚表征公众号", -1))])])]), _cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "dibu_two"
  }, "友情链接", -1)), _cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "dibu_three"
  }, [_createTextVNode("版权所有©2015-2022 四川众诚表征实验检测有限公司 "), _createElementVNode("a", {
    href: "https://beian.miit.gov.cn/",
    target: "_blank"
  }, "蜀ICP备2024048160号-1")], -1))])]), _createElementVNode("div", {
    class: "backhome",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.scrollToTop && $options.scrollToTop(...args))
  }, [_createVNode(_component_el_image, {
    src: "https://zcbiaozheng.oss-cn-chengdu.aliyuncs.com/zcbzcss/backhome.png"
  })])], 64);
}