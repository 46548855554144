export default {
  props: {
    menunum: {
      type: Number,
      value: 1
    }
  },
  data() {
    return {
      xzmenuid: 0,
      search_name: '',
      //搜索的关键词
      userinfo: {
        xingming: '柳千幻' //姓名
      } //用户信息
    };
  },
  methods: {
    menuMouseEnter(id) {
      console.log('鼠标进入了元素');
      var that = this;
      that.xzmenuid = id;
      // 可以在这里添加逻辑，比如改变元素的样式或状态  
    },
    menuMouseLeave() {
      console.log('鼠标离开了元素');
      var that = this;
      that.xzmenuid = 0;
      // 可以在这里添加逻辑，比如恢复元素的样式或状态  
    }
  }
};