import { $h, Tips, basePost, U, baseGet, URL, SplitArray, urlParams } from '@/utils/util.js';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import loading from '@/components/loading/loading.vue';
import zrcheader from '@/components/zrcheader/zrcheader.vue';
import zrcfooter from '@/components/zrcfooter/zrcfooter.vue';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
export default {
  name: 'IndexView',
  data() {
    return {
      menunum: 3,
      rongyulist: [{
        "id": 1,
        "title": "西南母婴服务行业市场品质品牌",
        "pic": "http://aibaobao.pflipin.com/zrcpic/rongyu1.jpg"
      }, {
        "id": 2,
        "title": "澳洲婴儿语言日本EduQ系列课程",
        "pic": "http://aibaobao.pflipin.com/zrcpic/rongyu2.jpg"
      }, {
        "id": 3,
        "title": "国际邓斯坦婴儿语言导师",
        "pic": "http://aibaobao.pflipin.com/zrcpic/rongyu3.jpg"
      }, {
        "id": 4,
        "title": "家政行业协会会员单位",
        "pic": "http://aibaobao.pflipin.com/zrcpic/rongyu4.jpg"
      }],
      //荣誉列表

      zrctoken: '',
      loadingStatus: true
    };
  },
  components: {
    loading,
    zrcheader,
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y]
    };
  },
  created() {
    var that = this;
    setTimeout(() => {
      this.loadingStatus = false;
    }, 2000);
  },
  onLoad(option) {
    console.log('参数');
  },
  watch: {},
  methods: {},
  mounted: function () {
    var that = this;
  }
};