import "core-js/modules/es.array.push.js";
import { $h, Tips, basePost, U, baseGet, URL, SplitArray, urlParams } from '@/utils/util.js';
import loading from '@/components/loading/loading.vue';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
export default {
  name: 'HomeView',
  data() {
    return {
      curzdf: 1,
      dltel: '',
      //登录手机号码
      dlsfz: '',
      //登录身份照吗
      dlmima: '',
      //登录密码
      yanzma: '',
      zrctoken: '',
      loadingStatus: true
    };
  },
  components: {
    loading
  },
  created() {
    var that = this;
    this.getParams();
    setTimeout(() => {
      this.loadingStatus = false;
    }, 2000);
  },
  onLoad(option) {
    console.log('参数');
  },
  watch: {},
  methods: {
    getParams() {
      var that = this;
      // 取到路由带过来的参数
      //console.log(that.$route.query);
      const option = this.$route.query;
      that.id = option.id || 1;
    },
    //提交保存任务
    lijidenglu: function () {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrpublic_api',
        a: 'dengluyanz_new',
        q: {
          curzdf: that.$data.curzdf,
          sjtel: that.$data.dltel,
          sfcard: that.$data.dlsfz,
          dlpass: that.$data.dlmima,
          yanzma: that.$data.yanzma
        }
      }, URL);
      baseGet(url, function (res) {
        Tips('登录成功', 'success');
        store.commit('SetZrctoken', res.data.zrctoken);
        router.push({
          path: '/home'
        });
      }, function (res) {
        Tips(res.msg, 'warning');
      });
    }
  },
  mounted: function () {
    var that = this;
  }
};