import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// main.js:注册所有图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
const app = createApp(App)

// vue3 给原型上挂载属性
//app.config.globalProperties.$echarts = echarts;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus)
app.use(vue3videoPlay)
app.use(store)
app.use(router)
app.mount('#app')

//console.log(app);

