import "core-js/modules/es.array.push.js";
import { $h, Tips, basePost, U, baseGet, URL, SplitArray, urlParams } from '@/utils/util.js';
import loading from '@/components/loading/loading.vue';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
export default {
  name: 'HomeView',
  data() {
    return {
      dengluinfo: [],
      //定义的登录数组

      current: 1,
      //当前菜单按钮的数字
      bkid: 0,
      bkidinfo: [],
      info: [],
      xiangmulist: [],
      bklist_pc: [],
      bkid_name: '',
      showsfinfo: 0,
      showyichangtp: 1,
      //弹屏异常提示
      yichanglist: [],
      taptxt_title: '',
      taptxt_deso: '',
      show_paymoney_tanp: 0,
      //输入支付金额开关
      dqxztitle: '',
      //当前选中的缴费的标题
      dqjfid: 0,
      //当前选中的缴费ID
      paymoney: 0,
      //填写的支付金额
      tlzfinfo: [],
      //通联支付的数据

      show_payerweima_tanp: 0,
      bkjflist: [],
      //报考缴费列表

      cusinfo: [],
      //客户信息

      zrctoken: '123',
      loadingStatus: true
    };
  },
  components: {
    loading
  },
  created() {
    var that = this;
    this.getParams();
    setTimeout(() => {
      this.loadingStatus = false;
    }, 2000);
  },
  onLoad(option) {
    console.log('参数');
  },
  watch: {},
  methods: {
    getParams() {
      var that = this;
      // 取到路由带过来的参数
      //console.log(that.$route.query);
      const option = this.$route.query;
      that.id = option.id || 1;
    },
    numToStr(num) {
      num = num.toString();
      return num;
    },
    //切换菜单类型
    qiehuanmenu: function (rwtktype) {
      var that = this;
      that.current = rwtktype;
    },
    //切换了报考类型
    baokaoChange: function (setval) {
      var that = this;
      console.log('切换了报考类型' + setval);
      that.bkid = setval;
      that.get_bkidinfo(); //获取专业数据
    },
    //立即退出
    lijituichu: function (setval) {
      var that = this;
      router.push({
        path: '/'
      });
    },
    //点击支付的时候获取相应的数据
    pautap: function (jfid) {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrbaokao_api',
        a: 'get_jfinfo_byjfid',
        q: {
          zrctoken: that.$data.zrctoken,
          jfid: jfid
        }
      }, URL);
      baseGet(url, function (res) {
        that.dqjfid = jfid;
        that.dqxztitle = res.data.info.dqxztitle;
        that.show_paymoney_tanp = 1; //输入支付金额开关
      });
    },
    //去获取缴费二维码
    get_jferweima: function () {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrbaokao_api',
        a: 'get_jferweima_by_tonglian'
      }, URL);
      var data = {
        zrctoken: that.$data.zrctoken,
        jfid: that.$data.dqjfid,
        paymoney: that.$data.paymoney
      };
      basePost(url, data, function (res) {
        that.tlzfinfo = res.data.info.tlzfinfo;
        that.show_payerweima_tanp = 1; //打开二维码扫码界面弹屏开关
      }, function (res) {
        Tips(res.msg, 'warning');
      });
    },
    //完成了交易
    go_wctlpay: function () {
      var that = this;
      that.tlzfinfo = [];
      that.show_payerweima_tanp = 0; //打开二维码扫码界面弹屏开关
      that.show_paymoney_tanp = 0;
      that.dqjfid = 0;
      that.paymoney = 0;
      that.get_bkidinfo(); //获取专业数据
    },
    //这里是获取登录状态
    getdengluzt: function () {
      var that = this;
      that.zrctoken = store.state.zrctoken;
      if (that.zrctoken.length == 0) {
        router.push({
          path: '/'
        });
      } else
        //正常获取到了token
        {
          var url = U({
            m: 'andrapi',
            c: 'my',
            a: 'getuserzhuangtai',
            q: {
              zrctoken: that.$data.zrctoken
            }
          }, URL);
          baseGet(url, function (res) {
            that.dengluinfo = res.data.info;
            that.$nextTick(function () {
              that.get_index(); //获取首页的数据
            });
          });
        }
    },
    //获取首页的数据
    get_index: function () {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrpublic_api',
        a: 'index',
        q: {
          longitude: 0,
          latitude: 0,
          zrctoken: that.$data.zrctoken,
          bkid: that.$data.bkid
        }
      }, URL);
      baseGet(url, function (res) {
        that.info = res.data.info;
        that.xiangmulist = res.data.info.xiangmulist;
        that.bkid = res.data.info.bkid;
        that.$nextTick(function () {
          that.get_bkidinfo(); //获取专业数据
          that.get_bzr_info(); //获取用户的数据
        });
      });
    },
    //获取首页的数据
    get_bkidinfo: function () {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrbaokao_api',
        a: 'get_bkidinfo',
        q: {
          zrctoken: that.$data.zrctoken,
          bkid: that.$data.bkid
        }
      }, URL);
      baseGet(url, function (res) {
        that.bkidinfo = res.data.info.bkidinfo;
        that.bklist_pc = res.data.info.bklist_pc;
        that.showsfinfo = res.data.info.showsfinfo;
        that.showyichangtp = res.data.info.showyichangtp;
        that.taptxt_title = res.data.info.taptxt_title;
        that.taptxt_deso = res.data.info.taptxt_deso;
        that.yichanglist = res.data.info.yichanglist;
        that.$nextTick(function () {
          that.get_bkjiaofeilist(); //获取学费的数据
        });
      });
    },
    //获取学费的数据
    get_bkjiaofeilist: function () {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrbaokao_api',
        a: 'get_bkjflist_byxzbkid',
        q: {
          zrctoken: that.zrctoken,
          bkid: that.$data.bkid
        }
      }, URL);
      baseGet(url, function (res) {
        that.bkjflist = res.data.info.bkjflist;
      });
    },
    //获取用户的数据
    get_bzr_info: function () {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrcustomer_api',
        a: 'get_bzr_info',
        q: {
          zrctoken: that.zrctoken
        }
      }, URL);
      baseGet(url, function (res) {
        that.cusinfo = res.data.info.cusinfo;
      });
    }
  },
  mounted: function () {
    var that = this;
    that.getdengluzt(); //获取和登录状态
  }
};