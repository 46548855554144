import "core-js/modules/es.array.push.js";
import { $h, Tips, basePost, U, baseGet, URL, SplitArray, urlParams } from '@/utils/util.js';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import loading from '@/components/loading/loading.vue';
import zrcheader from '@/components/zrcheader/zrcheader.vue';
import zrcfooter from '@/components/zrcfooter/zrcfooter.vue';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
export default {
  name: 'IndexView',
  data() {
    return {
      menunum: 1,
      cate_jiance_id: 1,
      cate_jiance_list: [{
        "id": 1,
        "cate_name": "能谱类"
      }, {
        "id": 2,
        "cate_name": "电镜类"
      }, {
        "id": 3,
        "cate_name": "波谱类"
      }, {
        "id": 4,
        "cate_name": "色谱质谱类"
      }, {
        "id": 5,
        "cate_name": "物理性能"
      }, {
        "id": 6,
        "cate_name": "声学类"
      }, {
        "id": 7,
        "cate_name": "力学类"
      }, {
        "id": 8,
        "cate_name": "热分析吸附类"
      }, {
        "id": 9,
        "cate_name": "元素光谱类"
      }],
      //服务列表 

      xzfwid: 0,
      hlsactive: 1,
      ppactive: 1,
      zxactive: 1,
      serverlist: [{
        "id": 1,
        "title": "X射线衍射仪XRD",
        "pic": "http://scigo-new.oss-cn-shanghai.aliyuncs.com/w/equipment/2021/08/31/%2020210831hh_F9yhgv.png",
        "deso": '布鲁克D8 advance、日本理学Smartlab SE、岛津XRD-6100、日本理学 Ultima IV'
      }, {
        "id": 2,
        "title": "X射线光电子能谱XPS",
        "pic": "http://scigo-new.oss-cn-shanghai.aliyuncs.com/w/equipment/2021/08/31/%2020210831j74DOwl_o.png",
        "deso": 'Thermo Kalpha；Thermo ESCALAB 250XI; Axis Ultra DLD Kratos AXIS SUPRA; PHI-5000versaprobeIII等'
      }, {
        "id": 3,
        "title": "小角散射仪SAXS/WAXS",
        "pic": "http://scigo-new.oss-cn-shanghai.aliyuncs.com/w/equipment/2021/09/08/%2020210908E9Kb6_1gk.png",
        "deso": 'Anton paar Saxsess MC2 、法国xenocs公司Xeuss 2.0/3.0'
      }, {
        "id": 4,
        "title": "紫外光电子能谱UPS",
        "pic": "http://scigo-new.oss-cn-shanghai.aliyuncs.com/w/equipment/2021/08/31/%20202108311y6rfQ-Br.png",
        "deso": 'ThermoFischer，ESCALAB 250Xi'
      }, {
        "id": 5,
        "title": "单晶X射线衍射仪SC-XRD",
        "pic": "http://scigo-new.oss-cn-shanghai.aliyuncs.com/w/equipment/2021/08/31/%2020210831Q3raGTEPL.png",
        "deso": 'Bruker D8 Quest，布鲁克 nano8advance，Bruker smart Apex，Bruker D8 350，Rigaku HighFlux Homelab等'
      }, {
        "id": 6,
        "title": "掠入射XRD GIXRD",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/upload/common/2021/07/13/273533035020816384.png",
        "deso": 'X Pert Powder 荷兰PANalytical 帕纳科'
      }, {
        "id": 7,
        "title": "离子减薄",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/upload/common/2021/06/24/266663632677376000.jpg",
        "deso": 'Gatan691、Gatan 695等'
      }, {
        "id": 8,
        "title": "原位扫描电子显微镜(SEM)",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e7%94%9f%e7%89%a9TEM.png-20240611110125.crdownload",
        "deso": 'TESCAN S8000'
      }, {
        "id": 9,
        "title": "超薄切片机",
        "pic": "http://scigo-new.oss-cn-shanghai.aliyuncs.com/w/equipment/2021/09/12/%2020210912gg8Iou-Fz.png",
        "deso": 'Leica EM UC7 FC7'
      }, {
        "id": 10,
        "title": "扫描电子显微镜SEM",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e5%9b%be%e7%89%871-20220124181730.png",
        "deso": '日立HITACHI SU8010，蔡司300，日立Hitachi S-4800等'
      }],
      //服务列表
      cate_moni_id: 1,
      cate_moni_list: [{
        "id": 1,
        "cate_name": "量子化学"
      }, {
        "id": 2,
        "cate_name": "第一性原理"
      }, {
        "id": 3,
        "cate_name": "分子模拟"
      }, {
        "id": 4,
        "cate_name": "有限元仿真"
      }, {
        "id": 5,
        "cate_name": "相分析"
      }, {
        "id": 6,
        "cate_name": "半导体器件仿真"
      }],
      //服务列表 
      monilist: [{
        "id": 1,
        "title": "量子化学综合计算",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/IGM-20240520183317.png",
        "deso": '量子化学综合计算'
      }, {
        "id": 2,
        "title": "DFT综合计算",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%2bZr-LUMO-20240520182905.bmp",
        "deso": 'DFT综合计算'
      }, {
        "id": 3,
        "title": "差分电荷密度",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/9bb4dd5d-87ef-4abe-905b-b598df7cb846-20240522165801.bmp",
        "deso": '差分电荷密度'
      }, {
        "id": 4,
        "title": "静电势（ESP）",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e9%9d%99%e7%94%b5%e5%8a%bf%ef%bc%88ESP%ef%bc%89-20231030190740.png",
        "deso": '静电势（ESP）'
      }, {
        "id": 5,
        "title": "激发态相关计算",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e6%bf%80%e5%8f%91%e6%80%81%e7%9b%b8%e5%85%b3%e8%ae%a1%e7%ae%97-20231030190610.png",
        "deso": '激发态相关计算'
      }, {
        "id": 6,
        "title": "电场仿真",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e7%94%b5%e5%9c%ba%e4%bb%bf%e7%9c%9f-20231030190406.png",
        "deso": '电场仿真'
      }, {
        "id": 7,
        "title": "热场仿真",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e7%83%ad%e5%9c%ba%e4%bb%bf%e7%9c%9f-20231030190355.png",
        "deso": '热场仿真'
      }, {
        "id": 8,
        "title": "光学仿真",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e5%85%89%e5%ad%a6%e4%bb%bf%e7%9c%9f-20231030190344.png",
        "deso": '光学仿真'
      }, {
        "id": 9,
        "title": "多场耦合",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e5%a4%9a%e5%9c%ba%e8%80%a6%e5%90%88-20231030190333.png",
        "deso": '多场耦合'
      }, {
        "id": 10,
        "title": "枝晶生长",
        "pic": "https://scigo-new.oss-cn-shanghai.aliyuncs.com/crm-upload/%e6%9e%9d%e6%99%b6%e7%94%9f%e9%95%bf-20231030190104.png",
        "deso": '枝晶生长'
      }],
      //模拟数据列表

      zixunlist: [{
        "id": 1,
        "title": "偏光显微镜在地质勘探中的用途及方法",
        "pic": "https://zcbiaozheng.oss-cn-chengdu.aliyuncs.com/zcbzcss/zixunpic.jpg",
        "deso": '通过偏振光来观察样品，利用偏振器产生偏振光，通过样品后，再通过分析器来观察；由于不同矿物对偏振光的吸收和折射不同，因此在偏光显微镜下可以观察到不同的光学特性，从而进行矿物鉴定和分析。',
        "daytxt": "30",
        "yeartxt": "2024-08"
      }],
      //资讯列表
      newslist: [{
        "id": 1,
        "title": "电子舌检测的十大应用领域",
        "deso": '作为一种模拟人类味觉感知的智能检测手段，通过其独特的传感器阵列和模式识别系统，快速、准确地识别和量化样品中的化学成分和味道特征。',
        "daytxt": "30",
        "yeartxt": "2024-08"
      }, {
        "id": 2,
        "title": "盐雾测试方法：分析原理及实施步骤",
        "deso": '盐雾试验的基本原理是通过人工配制一定浓度的盐水溶液，以雾化的形式喷洒在样品表面',
        "daytxt": "30",
        "yeartxt": "2024-08"
      }, {
        "id": 3,
        "title": "红外光谱中活性和非活性振动的区别",
        "deso": '红外光谱是基于分子振动和转动产生的吸收光谱。当分子吸收红外光时，其内部的振动和转动能级发生跃迁；不同振动模式的能级差异对应不同的红外吸收峰，从而可以得到分子结构和化学键的信息。',
        "daytxt": "30",
        "yeartxt": "2024-08"
      }],
      //资讯列表

      zrctoken: '',
      loadingStatus: true
    };
  },
  components: {
    loading,
    zrcheader,
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y]
    };
  },
  created() {
    var that = this;
    this.getParams();
    setTimeout(() => {
      this.loadingStatus = false;
    }, 2000);
  },
  onLoad(option) {
    console.log('参数');
  },
  watch: {},
  methods: {
    getParams() {
      var that = this;
      // 取到路由带过来的参数
      //console.log(that.$route.query);
      const option = this.$route.query;
      that.id = option.id || 1;
    },
    //提交保存任务
    lijidenglu: function () {
      var that = this;
      var url = U({
        m: 'andrapi',
        c: 'andrpublic_api',
        a: 'dengluyanz_new',
        q: {
          curzdf: that.$data.curzdf,
          sjtel: that.$data.dltel,
          sfcard: that.$data.dlsfz,
          dlpass: that.$data.dlmima,
          yanzma: that.$data.yanzma
        }
      }, URL);
      baseGet(url, function (res) {
        Tips('登录成功', 'success');
        store.commit('SetZrctoken', res.data.zrctoken);
        router.push({
          path: '/home'
        });
      }, function (res) {
        Tips(res.msg, 'warning');
      });
    },
    handleMouseEnter(id) {
      console.log('鼠标进入了元素');
      var that = this;
      that.xzfwid = id;
      // 可以在这里添加逻辑，比如改变元素的样式或状态  
    },
    handleMouseLeave() {
      console.log('鼠标离开了元素');
      var that = this;
      that.xzfwid = 0;
      // 可以在这里添加逻辑，比如恢复元素的样式或状态  
    },
    //切换材料测试
    SelectCateSonId: function (id) {
      var that = this;
      that.cate_jiance_id = id;
    },
    //切换模拟测试
    SelectCateMoniId: function (id) {
      var that = this;
      that.cate_moni_id = id;
    },
    //去新闻中心
    gozixun: function (id) {
      var that = this;
      that.cate_moni_id = id;
    }
  },
  mounted: function () {
    var that = this;
  }
};