import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "dengLoadingVue"
};
const _hoisted_2 = {
  key: 0,
  class: "maskVueHiddenShow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("view", _hoisted_1, [$props.status ? (_openBlock(), _createElementBlock("view", _hoisted_2, _cache[0] || (_cache[0] = [_createElementVNode("view", {
    class: "maskVueHiddenShowWrapper"
  }, [_createElementVNode("image", {
    src: "https://xcx.xuejingjy.com/xjjycss/static/loading.gif",
    class: "maskVueHiddenShowWrapperGif"
  })], -1)]))) : _createCommentVNode("", true)]);
}